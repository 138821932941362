<template>
<loader v-if="showloader==true"></loader>
  <div class="card">    
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            class="form-control form-control-solid w-450px ps-15"
            placeholder="Recherche"
            @change="searchdata"
            v-model="searchValue"
          />
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
          <!-- <button type="button" class="btn btn-light-primary me-3">
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button> -->
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_maj_quizz"
            style="float: right"
            @click="maj('', 'ajouter')"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Ajouter
          </button>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="table-responsive">
          <table
           class="table table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 text-uppercase"
              >
                <th>#</th>
                <th>Nom <i class="fa fa-sort" @click="sort('name')" style="cursor:pointer !important;"></i></th>
                <th>date <i class="fa fa-sort" @click="sort('created_at')" style="cursor:pointer !important;"></i></th>
                <th>Liste Questions</th>
                <th colspan="2">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in AllQuizz" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="odd">{{ date_format(item.created_at) }}</td>
                <td v-if="item.quizzquestion.length>0">
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_ListeQuestion"
                    @click="AffichelisteQ(item.quizzquestion)"
                    style="cursor: pointer; color: #009ef7"
                  >
                    <i class="fa fa-eye" aria-hidden="true" style="font-weight: bold; font-size: 18px"></i>
                  </span>
                </td>
                <td v-else>&nbsp;</td>
                <td class="odd" v-if="item.isActive == 0">
                  <i
                    class="fa fa-ban"
                    aria-hidden="true"
                    style="color: red; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="odd" v-if="item.isActive == 1">
                  <i
                    class="fa fa-check"
                    aria-hidden="true"
                    style="color: #50cd89; font-weight: bold; font-size: 18px"
                  ></i>
                </td>
                <td class="end">
                  <span class="action_icon"
                    ><i
                      class="fa fa-edit fa-2x"
                      @click="maj(item, 'editer')"
                      style="cursor: pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_maj_quizz"
                    ></i
                    >Edit</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row" style="margin-right:0">
              <div class="col-sm-2 col-md-2 d-flex align-items-center justify-content-center justify-content-md-start">
                  <label
            ><select
              class="form-select form-select-sm form-select-solid select_page"
              @change="searchdata"
              v-model="limit"
              style="cursor:pointer"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="100000">All</option>
            </select></label
          >
              </div>
              <div 
              class="col-sm-10 col-md-6 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                  <paginate
                      :page-count="totalpage"
                      :page-range="3"
                      :margin-pages="2"
                      :click-handler="clickCallback"
                      :prev-text="'Prev'"
                      :next-text="'Next'"
                      :container-class="'pagination'"
                      :page-class="'page-item'"
                  >
                  </paginate>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- *****************modal ajouter question ***************** -->
  <div
    class="modal fade bd-example-modal-lg"
    tabindex="-1"
    id="kt_modal_maj_quizz"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ajouter Quizz</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetData()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <form class="row g-3">
            <div class="row">
              <div class="col-md-6 padding_left_none">
                <label for="name" class="form-label required">Nom </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="form-control m-2"
                  v-model="add_data.name"
                />
              </div>
              <div class="col-md-6">
                <label for="isActive" class="form-label required"
                  >Status
                </label>
                <select class="form-select m-2" v-model="add_data.isActive">
                  <option value="1">Activer</option>
                  <option value="0">Desactiver</option>
                </select>
              </div>
            </div>
            <div class="row">
              &nbsp;
            </div>
            <div class="row">
              <label class="form-label">Image Couverture : </label>
              <input class="custom-file-input" type="file" @change="uploadFile" ref="file">
            </div>
            <div class="row" style="margin-top:1%;width:25%;">
              <img v-if="mediaquizz" :src="pathImg+'/'+ mediaquizz">
            </div>
            <div class="row">
              &nbsp;
            </div>
            <div class="row">
              <div class="col-md-12 padding_left_none">
                <label for="abonnement" class="form-label">Question </label>
                <Multiselect
                  v-model="selectQuestion"
                  mode="tags"
                  :object="true"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :options="AllQuestions"
                  label="name"
                  trackBy="name"
                />
              </div>
            </div>
            <div class="row">
              &nbsp;
            </div>
            <div class="row">
              &nbsp;
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetData()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="submit()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- *****************modal Liste Reponse quizz***************** -->
  <div class="modal fade" tabindex="-1" id="kt_modal_ListeQuestion">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Liste Question</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="resetDataQuizz()"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <table class="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr
                class="fw-bold fs-5 text-gray-800 border-bottom border-gray-200"
              >
                <th>#</th>
                <th>Question</th>
                <th>Ordre</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, index) in ListeQQ" :key="r.id">
                <td>{{ index + 1 }}</td>
                <td>{{ r.name }}</td>
                <td>
                    <input type="number" v-model="r.order" min="0">
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="resetDataQuizz()"
          >
            Fermer
          </button>
          <button type="button" class="btn btn-primary" @click="save_order()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiService } from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Editor from "@tinymce/tinymce-vue";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import loader from "../utility/loader.vue";
import Paginate from "vuejs-paginate-next";
export default {
  components: {
    Multiselect,
    loader,
    Paginate
  },
  data() {
    return {
      add_data: {
        isActive: 1,
        id:0
      },
      Images:null,
      AllQuizz: {},

      selectQuestion: [],
      OldselectQuestion: [],
      AllQuestions: {},
      
      formMode: "ajouter",
      pathImg:"",

      ListeQQ:{},
      id_select:0,
      mediaquizz:"",
      showloader: false,

      totalpage:0,
      currentpage:1,
      limit:10,
      sum:0,
      offset:0,
      searchValue:"",
      orderStatus: "asc",
    };
  },
  methods: {
    sort(index) {
      this.sortIndex = index;
      if (this.orderStatus === 'asc') {
          this.orderStatus = 'desc';
          this.AllQuizz = this.AllQuizz.sort((a, b) => {
            return b[index].localeCompare(a[index]);
          });
      } else if (this.orderStatus === 'desc') {
          this.orderStatus = 'asc';
          this.AllQuizz = this.AllQuizz.sort((a, b) => {
            return a[index].localeCompare(b[index]);
          });
      };
    },
    date_format(value){
      return moment(String(value)).format('MM/DD/YYYY hh:mm')
    },
    resetDataQuizz(){
        this.reply = [
          {
            nom: "",
            value:"0"
          },
        ];
    },
    getListeQuestion(id) {
      ApiService.setHeader();
      ApiService.get("tests/quizzquestion/listeSelect/"+id)
        .then(async (result) => {
          this.AllQuestions = await result.data;
          this.traitement_question();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async traitement_question() {
      await this.AllQuestions.forEach((item, index) => {
        this.AllQuestions[index]["value"] = item.id;
      });
    },
    async AffichelisteQ(data){
      this.ListeQQ = await data;
    },
    uploadFile() {
        this.Images = this.$refs.file.files[0];
    },
    submitFile(id) {
          const formData = new FormData();
          formData.append('file', this.Images);
          formData.append('id', id);
          const headers = { 'Content-Type': 'multipart/form-data' };
          ApiService.post("tests/quizz/UploadFile", formData, { headers }).then((res) => {
          res.data.files; // binary representation of the file
            res.status; // HTTP status
            this.showloader = false;
            this.alertSucess();
          });
    },
    submit() {
        this.showloader = true;
        const data_select_tmp = [];
        this.selectQuestion.forEach(async (item, index) => {
          await data_select_tmp.push(item.id);
        });

        let difference = this.OldselectQuestion.filter(x => !data_select_tmp.includes(x));

        this.add_data["listeIdQuestion"] = data_select_tmp;
        this.add_data["listeIdOldQuestion"] = difference;
        this.add_data["formMode"] = this.formMode;
        ApiService.setHeader();
        ApiService.post("tests/quizz/InsertOrUpdate", this.add_data)
          .then((response) => {                
                if(this.Images){
                    this.submitFile(response.data);
                }else{
                    this.showloader = false;
                    this.alertSucess();
                }
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    save_order(){
        this.showloader = true;
        const data = {};
        data["liste"] = this.ListeQQ;
        data["table"] = "quizzquestion";

        ApiService.setHeader();
        ApiService.post("tests/order/InsertOrUpdate", data)
          .then((response) => {   
                this.showloader = false;             
                this.alertSucess();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    alertSucess() {
      Swal.fire({
        text: "Enregistrer avec succes!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(function () {
        location.reload();
      });
    },
    getListeQuizz() {
      this.showloader = true;
      this.offset = this.limit * (this.currentpage-1);
      ApiService.setHeader();
      ApiService.query("tests/quizz",{
        params:{
            searchValue: this.searchValue,
            limit:this.limit,
            offset:this.offset
        }
      }).then(async (result) => {
          this.AllQuizz = await result.data.liste;
          this.sum = await result.data.sum;
          this.initpage();
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clickCallback (pageNum){
      this.currentpage = pageNum;
      this.getListeQuizz();
    },
    async initpage(){
      this.totalpage = await Math.ceil(this.sum / this.limit);
    },
    async searchdata(){
      this.currentpage = await 1;
      this.getListeQuizz();
    },

    async maj(item, val) {
      let id = 0;
      this.selectQuestion = [];
      this.OldselectQuestion = [];
      this.formMode = val;
      if (val == "editer") {
        const data_tmp = JSON.parse(JSON.stringify(item));
        this.add_data = data_tmp;
        this.traitement_select_question(item.quizzquestion);
        id = item.id;
        const media = await item.mediaquizz[0];
        if(media){
            this.mediaquizz = await media.path;
        }
      } else {
        this.add_data = {
          isActive: 1,
          id:0
        };
      }
      await this.getListeQuestion(id);
    },
    traitement_select_question(data) {
      data.forEach(async (item, index) => {
        data[index]["value"] = await item.id;
        this.selectQuestion.push(item);
        this.OldselectQuestion.push(item.id);
      });
    },
    resetData() {
      this.add_data = {
        isActive: 1,
        id:0
      }
      this.selectQuestion = [];
    },
    async getpathImg(){
        this.pathImg = await process.env.VUE_APP_FILE;
    }
  },
  mounted() {
    this.getListeQuizz();
    this.getpathImg();
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>